/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
/*LOGO*/
.brand__lockup img {
  height: 84px; }

/*CONTACT US*/
.footer-contact__contact-us {
  display: none; }

#drawer-nav .level-2 {
  width: 16.5%;
  padding: 8px; }

/*FOOTER NAV*/
.block-menu-block .content {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.front {
  /*WELCOME CTA*/
  /*NEWS DOORMAT*/
  /*CTA RIGHT COLUMN WITH ICONS*/
  /*FAQ TEASER*/
  /*CYCLE SLIDESHOW FOR FAQ*/ }
  .front .welcome,
  .front #founder {
    float: left;
    background: #dddddd;
    border: 1px solid #dedede;
    text-align: center;
    padding: 5% 4% 0 4%; }
    @media screen and (max-width: 767px) {
      .front .welcome,
      .front #founder {
        width: 100%;
        height: 265px;
        margin: 10px 0px 5px 0px; } }
    @media screen and (min-width: 768px) {
      .front .welcome,
      .front #founder {
        width: 50% !important;
        height: 300px;
        margin: 0px 0px 10px 0px;
        border: 1px solid #fff; } }
    @media screen and (min-width: 992px) {
      .front .welcome,
      .front #founder {
        width: 46.5% !important;
        height: 300px;
        margin: 0px 10px 20px 0px; } }
    @media screen and (min-width: 1200px) {
      .front .welcome,
      .front #founder {
        width: 46.5% !important;
        height: 300px;
        margin: 0px 10px 20px 0px; } }
  .front .welcome:hover, .front #founder:hover {
    background: #f1f1f1; }
  .front #director a,
  .front #founder a {
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: #555555; }
  .front #director .field-item .even {
    display: none; }
  @media screen and (max-width: 767px) {
    .front .view-news .teaser-image img {
      width: 100%; }
      .front .view-news .teaser-image img:before, .front .view-news .teaser-image img:after {
        content: " ";
        display: table; }
      .front .view-news .teaser-image img:after {
        clear: both; } }
  @media screen and (min-width: 768px) {
    .front .view-news .teaser-image img {
      width: 100%; }
      .front .view-news .teaser-image img:before, .front .view-news .teaser-image img:after {
        content: " ";
        display: table; }
      .front .view-news .teaser-image img:after {
        clear: both; } }
  @media screen and (min-width: 992px) {
    .front .view-news .teaser-image img {
      width: 95%; } }
  @media screen and (min-width: 1200px) {
    .front .view-news .teaser-image img {
      width: 95%; } }
  .front .view-news {
    padding-bottom: 10px; }
    @media screen and (max-width: 767px) {
      .front .view-news .views-row-first {
        border-bottom: solid 1px;
        margin-bottom: 20px;
        padding-bottom: 10px; } }
    @media screen and (min-width: 768px) {
      .front .view-news .views-row-first {
        border-bottom: solid 1px;
        margin-bottom: 20px;
        padding-bottom: 10px; } }
    @media screen and (min-width: 992px) {
      .front .view-news .views-row-first {
        border-bottom: 0px;
        margin-bottom: 0px;
        padding: 10px 0px 10px 0px; } }
    @media screen and (min-width: 1200px) {
      .front .view-news .views-row-first {
        border-bottom: 0px;
        margin-bottom: 0px;
        padding: 0px 0px 10px 0px; } }
  .front .application,
  .front .security,
  .front .research,
  .front .databases {
    background: #dddddd;
    padding: 10% 1%;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .front .application,
      .front .security,
      .front .research,
      .front .databases {
        width: 100%;
        height: 400px;
        margin-bottom: 15px !important;
        padding: 25% 1%;
        float: none; } }
    @media screen and (min-width: 768px) {
      .front .application,
      .front .security,
      .front .research,
      .front .databases {
        width: 23%;
        height: 450px;
        margin: 47px 10px 20px 0px;
        padding: 12% 1%;
        float: left; } }
    .front .application p, .front .application a,
    .front .security p,
    .front .security a,
    .front .research p,
    .front .research a,
    .front .databases p,
    .front .databases a {
      border: 0;
      text-decoration: none;
      color: #555555; }
    .front .application a img,
    .front .security a img,
    .front .research a img,
    .front .databases a img {
      border: 0; }
    .front .application h3,
    .front .security h3,
    .front .research h3,
    .front .databases h3 {
      color: #555555; }
    .front .application h3 a:after, .front .application .h3 a:after,
    .front .security h3 a:after,
    .front .security .h3 a:after,
    .front .research h3 a:after,
    .front .research .h3 a:after,
    .front .databases h3 a:after,
    .front .databases .h3 a:after {
      display: none; }
  .front .databases {
    margin-right: 0; }
  .front .application:hover,
  .front .security:hover,
  .front .research:hover,
  .front .databases:hover {
    background-color: #f1f1f1; }
  .front .col-md-8 .cycle-slideshow {
    background: #fff; }
  .front .cycle-slideshow h3 {
    margin-bottom: 0.3em; }
  .front #panels-ipe-regionid-sidebar {
    margin-top: .5em; }
  .front .views-row-faq-widget {
    width: 80%;
    left: 10% !important; }
  .front .pane-faq-panel-pane-2 .cycle-prev {
    display: block !important;
    left: 0 !important;
    opacity: 0.8 !important;
    background-size: 22.05px 56.25px;
    position: absolute;
    top: 0;
    width: 10%;
    z-index: 1002;
    height: 100%; }
  .front .pane-faq-panel-pane-2 .cycle-next {
    display: block !important;
    right: 0 !important;
    opacity: 0.8 !important;
    background-size: 22.05px 56.25px;
    position: absolute;
    top: 0;
    width: 10%;
    z-index: 1002;
    height: 100%;
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .front h3.pane-title {
      margin: 0px; } }
  @media screen and (min-width: 768px) {
    .front h3.pane-title {
      margin: 0px; } }
  @media screen and (min-width: 992px) {
    .front h3.pane-title {
      margin: 20px 0px 0px 0px; } }
  @media screen and (min-width: 1200px) {
    .front h3.pane-title {
      margin: 20px 0px 0px 0px; } }
  .front .pane-faq-panel-pane-2 .cycle-slideshow {
    margin-top: -20px; }
  .front .thumbnail-controller {
    margin: 0px 0px -30px 0px; }

/**
 * Styles targeting the homepage revised Oct 2019.
 *
 */
/* =Home Hero
 ----------------------------------------------------------*/
.cpo-home-hero-pane__wrap {
  position: relative; }

.cpo-home-hero-pane__image {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  @media screen and (min-width: 992px) {
    .cpo-home-hero-pane__image {
      height: 350px; } }
  @media screen and (min-width: 1200px) {
    .cpo-home-hero-pane__image {
      height: 400px; } }

.cpo-home-hero-pane__intro {
  margin: 30px 0; }
  @media screen and (min-width: 992px) {
    .cpo-home-hero-pane__intro {
      font-size: 18px; } }
  .cpo-home-hero-pane__intro p {
    padding: 0 8%; }
    @media screen and (min-width: 768px) {
      .cpo-home-hero-pane__intro p {
        padding: 0 17.5%;
        text-align: center; } }

/* =Highlights - Teasers
 ----------------------------------------------------------*/
.highlights {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #666666; }
  .highlights:before, .highlights:after {
    content: " ";
    display: table; }
  .highlights:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .highlights {
      display: flex; } }

.highlights-mobile-nav {
  background: rgba(207, 69, 32, 0.9);
  z-index: 12;
  padding: 10px 30px 20px;
  position: relative; }
  .highlights-mobile-nav:before, .highlights-mobile-nav:after {
    content: " ";
    display: table; }
  .highlights-mobile-nav:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .highlights-mobile-nav {
      display: none; } }
  .highlights-mobile-nav:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(207, 69, 32, 0.9) transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 50%; }
  .highlights-mobile-nav .wayfinder--highlights {
    width: 80%;
    margin: 0 auto; }
    .highlights-mobile-nav .wayfinder--highlights button {
      width: 100%;
      text-align: left; }
    .highlights-mobile-nav .wayfinder--highlights .selection-arrow span {
      top: 5px; }
    .highlights-mobile-nav .wayfinder--highlights .highlights__nav--sm.dropdown-menu {
      width: 90%; }
      .highlights-mobile-nav .wayfinder--highlights .highlights__nav--sm.dropdown-menu a {
        border-bottom: none; }

.highlights__nav {
  display: none; }
  @media screen and (min-width: 768px) {
    .highlights__nav {
      display: block;
      float: left;
      width: 33.33333%;
      margin: 0;
      font-size: 18px;
      background: #cf4520;
      color: #fff;
      opacity: 0.9;
      z-index: 10;
      padding: 40px 30px; } }
  @media screen and (min-width: 992px) {
    .highlights__nav {
      padding: 60px 30px 60px 60px; } }
  .highlights__nav li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative; }
    @media screen and (min-width: 992px) {
      .highlights__nav li {
        padding: 0; } }
  .highlights__nav li:last-child {
    border-width: 1px 0; }
  .highlights__nav a {
    display: block;
    padding: 10px 5px;
    border: none;
    color: #fff;
    text-align: right; }
    .highlights__nav a.active, .highlights__nav a:hover {
      background: #b31b1b; }

.highlights__nav a.active:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #cf4520;
  position: absolute;
  top: 15px;
  right: -40px; }

.highlights__teaser {
  display: none;
  position: relative;
  z-index: 11;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .highlights__teaser {
      min-height: 250px;
      padding: 50px; } }
  @media screen and (min-width: 768px) {
    .highlights__teaser {
      float: left;
      width: 66.66666%; } }

.highlights__teaser:nth-of-type(2) {
  display: block; }

.highlights__text {
  color: #fff; }
  @media screen and (max-width: 767px) {
    .highlights__text {
      text-align: center; } }
  @media screen and (min-width: 768px) {
    .highlights__text {
      width: 75%;
      padding: 0 0 0 60px;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
      -ms-transform: translateY(-50%);
      /* IE 9 */
      transform: translateY(-50%);
      /* IE 10, Fx 16+, Op 12.1+ */ } }

.highlights__title {
  font-size: 42px;
  font-family: "1898Sans-Bold", sans-serif;
  line-height: 1.2;
  padding: 0 0 10px; }
  @media screen and (min-width: 768px) {
    .highlights__title {
      text-align: left; } }

@media screen and (max-width: 767px) {
  .highlights__title:after {
    content: '\e80d';
    padding-left: 10px;
    font-size: 40%;
    vertical-align: middle;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5;
    color: #ffc72c; } }

.highlights__desc {
  font-size: 18px; }

.highlights__indicator {
  display: none; }
  @media screen and (min-width: 768px) {
    .highlights__indicator {
      display: block;
      position: absolute;
      height: 50%;
      top: 25%;
      right: 5%;
      border-left: 1px dotted #ffc72c;
      padding-left: 30px;
      z-index: 10;
      opacity: 0.7;
      transition: all 0.2s ease 0s; } }
  .highlights__indicator:after {
    content: "";
    position: relative;
    top: 25%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 40px;
    border-color: transparent transparent transparent #ffc72c; }

.highlights .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45); }
  @media screen and (min-width: 992px) {
    .highlights .mask {
      transition: all 0.2s ease 0s; } }

@media screen and (min-width: 992px) {
  .highlights.is-hover .highlights__indicator {
    opacity: 1.0; } }

@media screen and (min-width: 992px) {
  .highlights.is-hover .mask {
    background: rgba(0, 0, 0, 0.6); } }

/* =Section Titles
----------------------------------------------------------*/
.wcm-section__title {
  margin: 0 0 30px;
  border-top: 5px solid #b31b1b;
  text-align: center; }

.wcm-section__title span {
  display: inline-block;
  background: #b31b1b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -1px 0 0;
  padding: 5px 20px;
  letter-spacing: 2px; }
  @media screen and (min-width: 992px) {
    .wcm-section__title span {
      margin: 0; } }

/* =Bar Callouts
----------------------------------------------------------*/
@media screen and (max-width: 767px) {
  .bar-callout .row.is-flex {
    display: block; } }

.bar-panel--title a:after {
  content: ''; }

@media screen and (min-width: 768px) {
  .bar-panel--title {
    background: #f7f7f7; } }

.bar-panel--title--linked {
  cursor: pointer; }

.bar-callout .row:hover .bar-callout__image img {
  transform: scale(1.1); }

.bar-callout__text {
  margin-bottom: 20px; }
  @media screen and (min-width: 768px) {
    .bar-callout__text {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 70%;
      margin: 0;
      font-size: 13px; } }
  @media screen and (min-width: 992px) {
    .bar-callout__text {
      font-size: 15px; } }

.bar-callout__title {
  font-size: 15px; }
  @media screen and (min-width: 992px) {
    .bar-callout__title {
      font-size: 18px; } }
  .bar-callout__title a {
    color: #b31b1b; }
    .bar-callout__title a:hover {
      text-decoration: underline; }

.bar-callout__description {
  color: #666666;
  margin: 5px 0 0; }
  @media screen and (min-width: 992px) {
    .bar-callout__description {
      font-size: 18px;
      line-height: 1.6; } }
  .bar-callout__description:hover {
    text-decoration: underline; }

.bar-callout__image {
  overflow: hidden; }
  .bar-callout__image a {
    display: block; }

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s; }
  .bar-callout__image img:hover {
    transform: scale(1.1); }

.cpo-home-bar-callout {
  margin: 30px 0 0; }

/* =News Doormat
----------------------------------------------------------*/
.cpo-home-news {
  margin: 0 0 20px; }
  @media screen and (min-width: 992px) {
    .cpo-home-news {
      margin: 0; } }

.cpo-home-news__img {
  overflow: hidden; }
  .cpo-home-news__img a {
    display: block;
    border: none; }
  .cpo-home-news__img img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s; }

.cpo-home-news__img a img:hover {
  transform: scale(1.1); }

.cpo-home-news__date {
  font-size: 13px;
  margin: 15px 0 10px; }

.cpo-home-news__title {
  font-size: 20px;
  font-family: "1898Sans-Bold", sans-serif; }
  .cpo-home-news__title a {
    color: #b31b1b; }
    .cpo-home-news__title a:after {
      content: '\e80d';
      color: #e7751d;
      padding-left: 10px;
      font-size: 60%;
      vertical-align: middle;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      display: inline-block;
      text-decoration: inherit;
      text-align: center;
      /* opacity: .8; */
      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;
      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;
      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */
      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
      text-decoration: none;
      line-height: 1.5; }

/* =Misc
----------------------------------------------------------*/
.row.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0; }

/*PROFILE LIST VIEW*/
.profile-list-image img {
  width: 140px;
  height: 140px;
  margin-bottom: 0;
  border-radius: 70px;
  border: 4px solid #f2f0f1; }

.profile-list-image img:hover {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.075), 0 0 10px black; }

.profile-list {
  width: 100%;
  margin-bottom: 1em; }
  .profile-list:before, .profile-list:after {
    content: " ";
    display: table; }
  .profile-list:after {
    clear: both; }

.profile-list img {
  float: left;
  padding: 0 0.4em 0.4em 0; }

.profile-list p {
  padding-bottom: 0; }

/*PROFILE PAGE VIEW*/
.view-profiles .display-name {
  text-align: center; }

@media only screen and (min-width: 444px) {
  body {
    /*.display-name{ height: 90px;}*/ } }

@media only screen and (max-width: 443px) {
  body .display-name {
    margin-bottom: 52px;
    height: 100px; } }

h3.pane-title, h2.pane-title {
  padding: 10px 0px; }

@media screen and (min-width: 992px) {
  .profile {
    float: left;
    width: 47%;
    padding-right: 15px;
    clear: none;
    margin: 0;
    height: 180px;
    overflow: none; } }

.profile img {
  float: left;
  padding: 0 0.4em 0.4em 0; }

@media screen and (min-width: 992px) {
  .profile p {
    margin: 0; } }

.btn--wcm-profile {
  position: relative;
  display: inline-block;
  margin: 10px auto 20px auto;
  padding: 5px 20px;
  border-radius: 10px;
  border-width: 4px;
  font-size: 13px;
  line-height: 18px;
  color: #cf4520;
  background-color: transparent;
  border-color: #e7751d;
  width: 100%; }

/*NEWS LIST VIEW*/
.post-date {
  margin: 0.2em 0 0; }

.teaser-image img.panopoly-image-quarter {
  margin-bottom: 0.8em; }
  .teaser-image img.panopoly-image-quarter:before, .teaser-image img.panopoly-image-quarter:after {
    content: " ";
    display: table; }
  .teaser-image img.panopoly-image-quarter:after {
    clear: both; }
  @media screen and (min-width: 1200px) {
    .teaser-image img.panopoly-image-quarter {
      float: right;
      margin-left: 0.5em;
      margin-right: 0; } }
  @media screen and (min-width: 1200px) {
    .teaser-image img.panopoly-image-quarter {
      float: right;
      margin-left: 0.5em;
      margin-right: 0; } }

.page-news .post-date,
.page-residency-blog .post-date {
  margin: 0.2em 0 0; }

.page-news .teaser-text p {
  padding-bottom: 0.6rem; }

.ui-widget-content,
.ui-widget-header {
  background: inherit; }

.ui-tabs .ui-tabs-nav li.ui-state-active a,
.ui-tabs .ui-tabs-nav li.ui-state-hover a,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected a {
  color: #fff; }

.ui-tabs .ui-tabs-panel {
  border-top: 1px solid #d8d2c9;
  padding: 1em 0; }

.pic-with-caption {
  float: right;
  margin-left: 1em;
  width: 20%; }

.logo-position {
  width: 100%;
  margin-bottom: 0.5em;
  display: none; }
  .logo-position:before, .logo-position:after {
    content: " ";
    display: table; }
  .logo-position:after {
    clear: both; }
  @media screen and (min-width: 1200px) {
    .logo-position {
      float: right;
      width: 40%;
      margin: 0 0 0.5em 0.5em;
      display: block; } }
  @media screen and (min-width: 992px) {
    .logo-position {
      float: right;
      width: 40%;
      margin: 0 0 0.5em 0.5em;
      display: block; } }

tr.even, tr.odd,
.mobile-table tr.odd th, .mobile-table tr.odd,
.mobile-table tr.even th, .mobile-table tr.even {
  background-color: inherit;
  border-bottom: 1px solid #D8D2C9; }

.even td, .odd td,
th {
  padding: 0.5em 0.2em; }

table.mobile-table {
  margin: 0; }

.profile-list-image {
  width: 140px;
  margin: 0 auto; }
  @media screen and (min-width: 1200px) {
    .profile-list-image {
      margin: 0 auto .1em; } }
  @media screen and (min-width: 992px) {
    .profile-list-image {
      margin: 0 auto .2em; } }
  .profile-list-image img {
    width: 140px;
    height: 140px;
    margin-bottom: 0; }

.pane-profiles-panel-pane-2 .views-row,
.pane-profiles-panel-pane-3 .views-row, .pane-profiles-panel-pane-4 .views-row,
.pane-profiles-panel-pane-5 .views-row, .pane-profiles-panel-pane-6 .views-row,
.pane-profiles-panel-pane-7 .views-row, .pane-profiles-panel-pane-8 .views-row,
.pane-profiles-panel-pane-9 .views-row, .pane-profiles-panel-pane-10 .views-row,
.pane-profiles-panel-pane-11 .views-row {
  margin-right: 5% !important; }
  @media screen and (min-width: 1200px) {
    .pane-profiles-panel-pane-2 .views-row,
    .pane-profiles-panel-pane-3 .views-row, .pane-profiles-panel-pane-4 .views-row,
    .pane-profiles-panel-pane-5 .views-row, .pane-profiles-panel-pane-6 .views-row,
    .pane-profiles-panel-pane-7 .views-row, .pane-profiles-panel-pane-8 .views-row,
    .pane-profiles-panel-pane-9 .views-row, .pane-profiles-panel-pane-10 .views-row,
    .pane-profiles-panel-pane-11 .views-row {
      margin-right: 8px !important; } }
  @media screen and (min-width: 992px) {
    .pane-profiles-panel-pane-2 .views-row,
    .pane-profiles-panel-pane-3 .views-row, .pane-profiles-panel-pane-4 .views-row,
    .pane-profiles-panel-pane-5 .views-row, .pane-profiles-panel-pane-6 .views-row,
    .pane-profiles-panel-pane-7 .views-row, .pane-profiles-panel-pane-8 .views-row,
    .pane-profiles-panel-pane-9 .views-row, .pane-profiles-panel-pane-10 .views-row,
    .pane-profiles-panel-pane-11 .views-row {
      margin-right: 8px !important; } }

.page-node-4 .views-row-odd,
.page-node-4 .views-row-even,
.page-node-5 .views-row-odd,
.page-node-5 .views-row-even,
.page-node-6 .views-row-odd,
.page-node-6 .views-row-even,
.page-node-7 .views-row-odd,
.page-node-7 .views-row-even {
  float: left;
  text-align: left;
  width: 45% !important;
  margin: 0 5% 1em 0;
  min-height: 17em; }
  .page-node-4 .views-row-odd:before, .page-node-4 .views-row-odd:after,
  .page-node-4 .views-row-even:before,
  .page-node-4 .views-row-even:after,
  .page-node-5 .views-row-odd:before,
  .page-node-5 .views-row-odd:after,
  .page-node-5 .views-row-even:before,
  .page-node-5 .views-row-even:after,
  .page-node-6 .views-row-odd:before,
  .page-node-6 .views-row-odd:after,
  .page-node-6 .views-row-even:before,
  .page-node-6 .views-row-even:after,
  .page-node-7 .views-row-odd:before,
  .page-node-7 .views-row-odd:after,
  .page-node-7 .views-row-even:before,
  .page-node-7 .views-row-even:after {
    content: " ";
    display: table; }
  .page-node-4 .views-row-odd:after,
  .page-node-4 .views-row-even:after,
  .page-node-5 .views-row-odd:after,
  .page-node-5 .views-row-even:after,
  .page-node-6 .views-row-odd:after,
  .page-node-6 .views-row-even:after,
  .page-node-7 .views-row-odd:after,
  .page-node-7 .views-row-even:after {
    clear: both; }
  @media screen and (min-width: 992px) {
    .page-node-4 .views-row-odd,
    .page-node-4 .views-row-even,
    .page-node-5 .views-row-odd,
    .page-node-5 .views-row-even,
    .page-node-6 .views-row-odd,
    .page-node-6 .views-row-even,
    .page-node-7 .views-row-odd,
    .page-node-7 .views-row-even {
      width: 28.33% !important;
      margin-right: 5% !important; } }
  @media screen and (min-width: 1200px) {
    .page-node-4 .views-row-odd,
    .page-node-4 .views-row-even,
    .page-node-5 .views-row-odd,
    .page-node-5 .views-row-even,
    .page-node-6 .views-row-odd,
    .page-node-6 .views-row-even,
    .page-node-7 .views-row-odd,
    .page-node-7 .views-row-even {
      width: 45%;
      margin-right: 5%; } }

.page-about-us-our-faculty a > img,
.page-our-people-faculty a > img,
.page-our-people-crna-roster a > img {
  border-radius: 0; }

.poster {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #e2e2d8; }

.poster img {
  margin-bottom: 0.5em; }

/*NEWS PAGE VIEW*/
img.panopoly-image-quarter:before, img.panopoly-image-quarter:after {
  content: " ";
  display: table; }

img.panopoly-image-quarter:after {
  clear: both; }

@media screen and (max-width: 767px) {
  img.panopoly-image-quarter {
    float: none !important;
    width: 100% !important;
    max-width: 100% !important; }
    img.panopoly-image-quarter:before, img.panopoly-image-quarter:after {
      content: " ";
      display: table; }
    img.panopoly-image-quarter:after {
      clear: both; } }

@media screen and (min-width: 768px) {
  img.panopoly-image-quarter {
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important; } }

@media screen and (min-width: 992px) {
  img.panopoly-image-quarter {
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important; } }

@media screen and (min-width: 1200px) {
  img.panopoly-image-quarter {
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important; } }

.view-news .news-story__type--1 {
  background: #e7751d;
  padding: 0px 10px;
  float: right; }
  @media screen and (max-width: 767px) {
    .view-news .news-story__type--1 {
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .view-news .news-story__type--1 {
      width: 30%;
      margin-right: 8px; } }
  @media screen and (min-width: 992px) {
    .view-news .news-story__type--1 {
      width: 30%;
      margin-right: 8px; } }
  @media screen and (min-width: 1200px) {
    .view-news .news-story__type--1 {
      width: 30%;
      margin-right: 8px; } }

.view-news .news-story__type a {
  color: #fff; }

.view-news .news-story__type a:hover {
  text-decoration: none; }

@media screen and (max-width: 767px) {
  .view-news .teaser-title {
    margin: 0px 0 0; } }

@media screen and (min-width: 768px) {
  .view-news .teaser-title {
    margin: -20px 0 0; } }

@media screen and (min-width: 992px) {
  .view-news .teaser-title {
    margin: -20px 0 0; } }

@media screen and (min-width: 1200px) {
  .view-news .teaser-title {
    margin: -20px 0 0; } }

.view-news .teaser-text {
  padding: 0px 0px 10px 0px; }

.view-news .view-teaser {
  padding: 0px 0px 40px 0px; }

.view-news .news-story__tags, .view-news .story-tags {
  margin: 20px 0 0;
  font-size: 12px;
  background: url(/sites/all/themes/wcm_cpo_subtheme/images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding: 0px 0px 0px 22px; }

.news-story__tags, .story-tags {
  margin: 20px 0 0;
  font-size: 12px;
  background: url(/sites/all/themes/wcm_cpo_subtheme/images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding: 10px 0px 0px 22px; }

.social-share {
  margin: 15px 0px 10px 0px;
  padding: 0; }

/*FAQ*/
.question:before {
  display: none; }

/*SIDEBAR*/
.information-sidebar {
  font-size: 14px;
  text-align: center; }

/* =News Post
----------------------------------------------------------*/
.news-featured-image .hero-image img {
  width: auto; }

/**
 * Styles targeting the Directory
 *
 */
/* =Directory List View
 ----------------------------------------------------------*/
.views-exposed-form .form-select {
  max-width: none; }
  @media screen and (min-width: 992px) {
    .views-exposed-form .form-select {
      min-width: 500px; } }

.views-exposed-form #edit-student-type-wrapper {
  float: none;
  clear: both;
  padding: 20px 0 0; }

#status-type-toggle {
  display: none;
  margin: 15px 0 0; }
  #status-type-toggle > label {
    display: block; }
  #status-type-toggle .form-check {
    display: inline-block;
    margin-right: 10px; }
    #status-type-toggle .form-check label {
      font-weight: normal; }

.directory-teaser--active-alumni__no-results {
  display: none; }

.directory-group {
  margin: 20px 0; }

.directory-teaser {
  margin: 0 0 20px; }
  .directory-teaser .field-profile-image {
    margin: 0 0 10px; }
    .directory-teaser .field-profile-image img {
      max-width: 220px; }
      @media screen and (min-width: 768px) {
        .directory-teaser .field-profile-image img {
          max-width: none;
          width: 100%;
          height: auto; } }

.directory-teaser__name {
  font-weight: 700; }

.field-professional-title {
  font-style: italic; }
  .field-professional-title ul {
    margin: 0;
    padding: 0; }
  .field-professional-title li {
    margin: 0 0 5px;
    padding: 0;
    list-style-type: none; }

.directory-teaser__profile-links {
  margin: 10px 0 0; }
  .directory-teaser__profile-links a {
    margin: 0 0 10px; }

/* =Styles by Directory Filter by
----------------------------------------------------------*/
.directory-teaser .field-profile-image,
.directory-teaser .directory-teaser__profile-links {
  display: none; }

.directory-filter-by-15 .directory-teaser .field-profile-image,
.directory-filter-by-15 .directory-teaser .directory-teaser__profile-links,
.directory-filter-by-16 .directory-teaser .field-profile-image,
.directory-filter-by-16 .directory-teaser .directory-teaser__profile-links {
  display: block; }

/* =Directory Sort
----------------------------------------------------------*/
.view-directory.view-display-id-page_1 .view-filters {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .view-directory.view-display-id-page_1 .view-filters:before, .view-directory.view-display-id-page_1 .view-filters:after {
    content: " ";
    display: table; }
  .view-directory.view-display-id-page_1 .view-filters:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .view-directory.view-display-id-page_1 .view-filters {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .view-directory.view-display-id-page_1 .view-filters {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .view-directory.view-display-id-page_1 .view-filters {
      width: 1170px; } }
  .view-directory.view-display-id-page_1 .view-filters form {
    margin: 0 0 20px; }

.view-directory.view-display-id-page_1 a.tabledrag-handle {
  border: none; }

/* =Misc
----------------------------------------------------------*/
.row.gutter-50 {
  margin-left: -50px;
  margin-right: -50px; }
  .row.gutter-50 [class*="col-"] {
    padding-left: 50px;
    padding-right: 50px; }

@media screen and (min-width: 768px) {
  .row.is-flex-sm {
    display: flex;
    flex-wrap: wrap; }
    .row.is-flex-sm:before {
      display: block; } }

@media screen and (min-width: 768px) {
  .row.is-flex-sm > [class*='col-'] {
    display: flex;
    flex-direction: column; } }

/**
 * Styles targeting the Biblio Publications
 *
 */
.page-publications-biblio .site-main {
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px; }
  .page-publications-biblio .site-main:before, .page-publications-biblio .site-main:after {
    content: " ";
    display: table; }
  .page-publications-biblio .site-main:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .page-publications-biblio .site-main {
      width: 750px; } }
  @media screen and (min-width: 992px) {
    .page-publications-biblio .site-main {
      width: 970px; } }
  @media screen and (min-width: 1200px) {
    .page-publications-biblio .site-main {
      width: 1170px; } }

.view-publications h3 {
  margin-bottom: 10px; }

.publication-teaser {
  margin: 0 0 20px; }

.views-field-field-biblio-pub-conf-name {
  margin: 10px 0 0; }
  .views-field-field-biblio-pub-conf-name > div {
    display: inline; }

.views-field-field-biblio-pub-conf-info > div {
  display: inline; }

.views-field-field-biblio-pub-conf-award > div {
  display: inline; }

.views-label-field-biblio-pub-conf-name,
.views-label-field-biblio-pub-conf-info,
.views-label-field-biblio-pub-conf-award {
  font-weight: 700; }

/* =Biblio Node
----------------------------------------------------------*/
.abstract-intro {
  margin: 0 0 5px; }

.field-biblio-pub-conf-award {
  margin: 10px 0; }
  .field-biblio-pub-conf-award .field-label {
    display: inline; }
