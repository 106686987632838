/**
 * Styles targeting the Directory
 *
 */

 /* =Directory List View
 ----------------------------------------------------------*/

.views-exposed-form .form-select {
  max-width: none;
  @include breakpoint($md) {
    min-width: 500px;
  }
}

.views-exposed-form #edit-student-type-wrapper {
  float: none;
  clear: both;
  padding: 20px 0 0;
}

#status-type-toggle {
  display: none;
  margin: 15px 0 0;
  > label {
    display: block;
  }
  .form-check {
    display: inline-block;
    margin-right: 10px;
    label {
      font-weight: normal;
    }
  }
}

.directory-teaser--active-alumni__no-results {
  display: none;
}

.directory-group {
  margin: 20px 0;
}

.directory-teaser {
 margin: 0 0 20px;
 .field-profile-image {
   margin: 0 0 10px;
   img {
     max-width: 220px;
     @include breakpoint($sm) {
       max-width: none;
       width: 100%;
       height: auto;
     }
   }
 }
}

.directory-teaser__name {
  font-weight: 700;
}

.field-professional-title {
  font-style: italic;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0 0 5px;
    padding: 0;
    list-style-type: none;
  }
}

.directory-teaser__profile-links {
  margin: 10px 0 0;
  a {
    margin: 0 0 10px;
  }
}

/* =Styles by Directory Filter by
----------------------------------------------------------*/

.directory-teaser {
  .field-profile-image,
  .directory-teaser__profile-links {
    display: none;
  }
}


.directory-filter-by-15,
.directory-filter-by-16 {
  .directory-teaser {
    .field-profile-image,
    .directory-teaser__profile-links {
      display: block;
    }
  }
}



/* =Directory Sort
----------------------------------------------------------*/

.view-directory.view-display-id-page_1 {
  .view-filters {
    @include container-wrap();
    form {
      margin: 0 0 20px;
    }
  }
  a.tabledrag-handle {
    border: none;
  }
}

/* =Misc
----------------------------------------------------------*/

// Client-requested larger Bootstrap grid gutters just for the directory items
.row.gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
  [class*="col-"] {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.row.is-flex-sm {
  @include breakpoint($sm) {
    display: flex;
    flex-wrap: wrap;
    &:before {
      display: block;
    }
  }
}

.row.is-flex-sm > [class*='col-'] {
  @include breakpoint($sm) {
    display: flex;
    flex-direction: column;
  }
}



 
 