.front{

  /*WELCOME CTA*/
  .welcome,
  #founder {
    float: left;
    background: $wcm-border-gray;
    border: 1px solid #dedede;
    text-align: center;
    padding: 5% 4% 0 4%;
    @include breakpoint($xs){
      width: 100%;
      height: 265px;
      margin: 10px 0px 5px 0px;    
    }  
    @include breakpoint($sm){
      width: 50% !important;
      height: 300px;
      margin: 0px 0px 10px 0px;
      border: 1px solid $wcm-white;
    }
    @include breakpoint($md){
      width: 46.5% !important;
      height: 300px;
      margin: 0px 10px 20px 0px; 
      }
    @include breakpoint($lg){
      width: 46.5% !important;
      height: 300px;
      margin: 0px 10px 20px 0px;
    }
  }

  .welcome:hover, #founder:hover {
    background: lighten($wcm-border-gray, 8%);
  }

  #director,
  #founder{
    a{
      text-decoration: none;
      padding: 0;
      margin: 0;
      color: $wcm-dark-gray;
    }
  }

  #director .field-item .even {
    display: none;
  }

  /*NEWS DOORMAT*/

  .view-news .teaser-image img {
    @include breakpoint($xs){
      width: 100%;
      @include clearfix;
    }  
    @include breakpoint($sm){
      width: 100%;
      @include clearfix;
    }  
    @include breakpoint($md){
      width: 95%;
    }
    @include breakpoint($lg){
    width: 95%;
    }
  }

  .view-news{
    padding-bottom: 10px;
    .views-row-first{

      @include breakpoint($xs){
        border-bottom: solid 1px;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }

      @include breakpoint($sm){
        border-bottom: solid 1px;
        margin-bottom: 20px;
        padding-bottom: 10px;
      }    

      @include breakpoint($md){
        border-bottom: 0px;
        margin-bottom: 0px;
        padding: 10px 0px 10px 0px;
      }  

      @include breakpoint($lg){
        border-bottom: 0px;
        margin-bottom: 0px;
        padding: 0px 0px 10px 0px;
      }      
    }
  }


  /*CTA RIGHT COLUMN WITH ICONS*/
  .application,
  .security,
  .research,
  .databases {
    background: $wcm-border-gray;
    padding: 10% 1%;  
    text-align: center;

    @include breakpoint($xs){
      width: 100%;
      height: 400px;
      margin-bottom: 15px !important;
      padding: 25% 1%;
      float: none;
    }    
    @include breakpoint($sm){
      width: 23%;
      height: 450px;
      margin: 47px 10px 20px 0px;
      padding: 12% 1%;
      float: left;
    }
    p, a {
      border: 0;
      text-decoration: none;
      color: $wcm-dark-gray;
    }

    a img {
      border: 0;
    }

    h3 {
      color: $wcm-dark-gray;
    }

    h3 a:after, .h3 a:after{
      display: none;
    }
  }

  .databases {
    margin-right: 0;
  }

  .application:hover,
  .security:hover,
  .research:hover,
  .databases:hover {
    background-color: lighten($wcm-border-gray, 8%);
  }


  /*FAQ TEASER*/
  /*CYCLE SLIDESHOW FOR FAQ*/  
  .col-md-8 .cycle-slideshow {
    background: $wcm-white;
  }
  
  .cycle-slideshow h3 {
    margin-bottom: 0.3em;
  }

  #panels-ipe-regionid-sidebar {
    margin-top: .5em;
  }

  .views-row-faq-widget{
    width: 80%;
    left: 10%!important;
  }

  .pane-faq-panel-pane-2{
    .cycle-prev {
      display: block !important;
      left: 0!important;
      opacity: 0.8!important;
      background-size: 22.05px 56.25px;
      position: absolute;
      top: 0;
      width: 10%;
      z-index: 1002;
      height: 100%;
    }

    .cycle-next {
      display: block !important;
      right: 0!important;
      opacity: 0.8!important;
      background-size: 22.05px 56.25px;
      position: absolute;
      top: 0;
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer;
    }
  }

  h3.pane-title{
    @include breakpoint($xs){
      margin: 0px;
      }
    @include breakpoint($sm){
      margin: 0px;
      }
    @include breakpoint($md){
      margin: 20px 0px 0px 0px;
      }
    @include breakpoint($lg){
      margin: 20px 0px 0px 0px;
      }
    }

  .pane-faq-panel-pane-2{
   .cycle-slideshow{
      margin-top: -20px;
    }
  }

  .thumbnail-controller{
    margin: 0px 0px -30px 0px;
  }
}
