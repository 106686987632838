#drawer-nav .level-2 {
    width: 16.5%;
    padding: 8px;
    }


/*FOOTER NAV*/
.block-menu-block .content {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
    }