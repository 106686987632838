/**
 * Styles targeting the homepage revised Oct 2019.
 *
 */

 /* =Home Hero
 ----------------------------------------------------------*/

.cpo-home-hero-pane__wrap {
  position: relative;
}

.cpo-home-hero-pane__image {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include breakpoint($md) {
    height: 350px;
  }
  @include breakpoint($lg) {
    height: 400px;
  }
}

.cpo-home-hero-pane__intro {
  margin: 30px 0;
  @include breakpoint($md) {
    font-size: 18px;
  }
  p {
    padding: 0 8%;
    @include breakpoint($sm) {
      padding: 0 17.5%;
      text-align: center;
    }
  }
}

/* =Highlights - Teasers
 ----------------------------------------------------------*/

.highlights {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: $wcm-med-gray;
  @include clearfix();
  @include breakpoint($sm) {
    display: flex;
  }
}

.highlights-mobile-nav {
  background: rgba(207,69,32,.9);
  z-index: 12;
  padding: 10px 30px 20px;
  position: relative;
  @include clearfix();
  @include breakpoint($sm) {
    display: none;
  }
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: rgba(207,69,32,.9) transparent transparent transparent;
    position: absolute;
    bottom: -10px;
    right: 50%
  }
  .wayfinder--highlights {
    width: 80%;
    margin: 0 auto;
    button {
      width: 100%;
      text-align: left;
    }
    .selection-arrow span {
      top: 5px;
    }
    .highlights__nav--sm.dropdown-menu {
      width: 90%;
      a {
        border-bottom: none;
      }
    }
  }
}

.highlights__nav {
  display: none;
  @include breakpoint($sm) {
    display: block;
    float: left;
    width: 33.33333%;
    margin: 0;
    font-size: 18px;
    background: $wcm-dark-orange;
    color: $wcm-white;
    opacity: 0.9;
    z-index: 10;
    padding: 40px 30px;
  }
  @include breakpoint($md) {
    padding: 60px 30px 60px 60px;
  }
  li {
    list-style-type: none;
    padding: 0;
    border: 1px dotted;
    border-width: 1px 0 0;
    position: relative;
    @include breakpoint($md) {
      padding: 0;
    }
  }
  li:last-child {
    border-width: 1px 0;
  }
  a {
    display: block;
    padding: 10px 5px;
    border: none;
    color: $wcm-white;
    text-align: right;
    &.active,
    &:hover {
      background: $wcm-red;
    }
  }
}

.highlights__nav a.active {
  &:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent $wcm-dark-orange;
    position: absolute;
    top: 15px;
    right: -40px;
  }
}

.highlights__teaser {
  display: none;
  position: relative;
  z-index: 11;
  cursor: pointer;
  @include breakpoint($xs) {
    min-height: 250px;
    padding: 50px;
  }
  @include breakpoint($sm) {
    float: left;
    width: 66.66666%;
  }
}

// Show first teaser on load
.highlights__teaser:nth-of-type(2) {
  display: block;
}

.highlights__text {
  color: $wcm-white;
  @include breakpoint($xs) {
    text-align: center;
  }
  @include breakpoint($sm) {
    width: 75%;
    padding: 0 0 0 60px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
    -ms-transform: translateY(-50%); /* IE 9 */
    transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
  }
}

.highlights__title {
  font-size: 42px;
  font-family: $wcm-bold;
  line-height: 1.2;
  padding: 0 0 10px;
  @include breakpoint($sm) {
    text-align: left;
  }
}

.highlights__title:after {
  @include breakpoint($xs) {
    content: '\e80d';
    padding-left: 10px;
    font-size: 40%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
    color: $wcm-yellow;    
  }
}

.highlights__desc {
  font-size: 18px;
}

.highlights__indicator {
  display: none;
  @include breakpoint($sm) {
    display: block;
    position: absolute;
    height: 50%;
    top: 25%;
    right: 5%;
    border-left: 1px dotted $wcm-yellow;
    padding-left: 30px;
    z-index: 10;
    opacity: 0.7;
    transition: all 0.2s ease 0s;
  }
  &:after {
    content: "";
    position: relative;
    top: 25%;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 40px;
    border-color: transparent transparent transparent $wcm-yellow;
  }
}

.highlights .mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.45);
  @include breakpoint($md) {
    transition: all 0.2s ease 0s;
  }
}

.highlights.is-hover {
  .highlights__indicator {
    @include breakpoint($md) {
      opacity: 1.0;
    }
  }
  .mask {
    @include breakpoint($md) {
      background: rgba(0,0,0,0.6);
    }
  }
}

/* =Section Titles
----------------------------------------------------------*/

.wcm-section__title {
  margin: 0 0 30px;
  border-top: 5px solid #b31b1b;
  text-align: center;
}

.wcm-section__title span {
  display: inline-block;
  background: #b31b1b;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin: -1px 0 0;
  padding: 5px 20px;
  letter-spacing: 2px;
  @include breakpoint($md) {
    margin: 0;
  }
}

/* =Bar Callouts
----------------------------------------------------------*/

.bar-callout .row.is-flex {
  @include breakpoint($xs) {
    display: block;
  }
}

.bar-panel--title {
  a {
    &:after {
      content: '';
    }
  }
  @include breakpoint($sm) {
    background: $wcm-bg-gray;
  }
}

.bar-panel--title--linked {
  cursor: pointer;
}

.bar-callout .row {
  &:hover {
    .bar-callout__image img {
        transform: scale(1.1);
    }
  }
}

.bar-callout__text {
  margin-bottom: 20px;
  
  @include breakpoint($sm) {
    text-align: center;
    @include center();
    width: 70%;
    margin: 0;
    font-size: 13px;
  }
  @include breakpoint($md) {
    font-size: 15px
  }
}

.bar-callout__title {
  font-size: 15px;
  @include breakpoint($md) {
    font-size: 18px;
  }
  a {
    color: $wcm-red;
    &:hover {
      text-decoration: underline;
    }
  }
}

.bar-callout__description {
  color: $wcm-med-gray;
  margin: 5px 0 0;
  @include breakpoint($md) {
    font-size: 18px;
    line-height: 1.6;
  }
  &:hover {
    text-decoration: underline;
  }
}

.bar-callout__image {
  overflow: hidden;
  a {
    display: block;
  }
}

.bar-callout__image img {
  width: 100%;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: scale(1.1);
  }
}

.cpo-home-bar-callout {
  margin: 30px 0 0;
}

/* =News Doormat
----------------------------------------------------------*/

.cpo-home-news {
  margin: 0 0 20px;
  @include breakpoint($md) {
    margin: 0;
  }
}

.cpo-home-news__img {
  overflow: hidden;
  a {
    display: block;
    border: none;
  }
  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease 0s;
  }
}

.cpo-home-news__img a img:hover {
  transform: scale(1.1);
}

.cpo-home-news__date {
  font-size: 13px;
  margin: 15px 0 10px;
}

.cpo-home-news__title {
  font-size: 20px;
  font-family: $wcm-bold;
  a {
    color: $wcm-red;
    &:after {
    content: '\e80d';
    color: $wcm-bright-orange;
    padding-left: 10px;
    font-size: 60%;
    vertical-align: middle;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
    }
  }
}

/* =Misc
----------------------------------------------------------*/

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}




 
 