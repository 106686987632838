/**
 * Styles targeting the Biblio Publications
 *
 */

.page-publications-biblio .site-main {
  @include container-wrap();
}

.view-publications {
  h3 {
    margin-bottom: 10px;
  }
}
 
.publication-teaser {
  margin: 0 0 20px;
}

.views-field-field-biblio-pub-conf-name {
  margin: 10px 0 0;
  > div {
    display: inline;
  }
}

.views-field-field-biblio-pub-conf-info {
  > div {
    display: inline;
  }
}

.views-field-field-biblio-pub-conf-award {
  > div {
    display: inline;
  }
}

.views-label-field-biblio-pub-conf-name,
.views-label-field-biblio-pub-conf-info,
.views-label-field-biblio-pub-conf-award {
  font-weight: 700;
}

/* =Biblio Node
----------------------------------------------------------*/

.abstract-intro {
  margin: 0 0 5px;
}

.field-biblio-pub-conf-award {
  margin: 10px 0;
  .field-label {
    display: inline;
  }
}

