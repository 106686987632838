/*PROFILE LIST VIEW*/
.profile-list-image{
  img {
    width: 140px;
    height: 140px;
    margin-bottom: 0;
    border-radius: 70px;
    border: 4px solid #f2f0f1;
    }
  img:hover{
    box-shadow: inset 0 0 5px rgba(0,0,0,.075), 0 0 10px rgba(0, 0, 0, 1);
    }
}

.profile-list {
  @include clearfix();
  width: 100%;
  margin-bottom: 1em;
}

.profile-list img {
  float: left;
  padding: 0 0.4em 0.4em 0;
}

.profile-list p {
  padding-bottom: 0;
}

/*PROFILE PAGE VIEW*/
.view-profiles .display-name{
  text-align: center;
}

@media only screen and (min-width: 444px) {
    body {
        /*.display-name{ height: 90px;}*/
    }
}

@media only screen and (max-width: 443px) {
    body {
        .display-name{
        margin-bottom: 52px;
        height: 100px;}
    }
}

h3.pane-title, h2.pane-title {
  padding: 10px 0px;
}

.profile {
  @include breakpoint($md){
    float: left;
    width: 47%;
    padding-right: 15px;
    clear: none;
    margin: 0;
    height: 180px;
    overflow: none;
  }
}

.profile img {
  float: left;
  padding: 0 0.4em 0.4em 0;
}

.profile p {
  @include breakpoint($md){
    margin: 0;
  }
}

.btn--wcm-profile{
    position: relative;
    display: inline-block;
    margin: 10px auto 20px auto;
    padding: 5px 20px;
    border-radius: 10px;
    border-width: 4px;
    font-size: 13px;
    line-height: 18px;
    color: $wcm-dark-orange;
    background-color: transparent;
    border-color: $wcm-bright-orange;
    width:100%;
}

/*NEWS LIST VIEW*/
.post-date {
  margin: 0.2em 0 0;
}

.page-news h2.teaser-title,
.page-residency-blog h2.teaser-title  {
}

.teaser-image img.panopoly-image-quarter {
  @include clearfix();
  margin-bottom: 0.8em;
  @include breakpoint($lg){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }
  @include breakpoint($lg){
    float: right;
    margin-left: 0.5em;
    margin-right: 0;
  }
}

.page-news .post-date,
.page-residency-blog .post-date {
  margin: 0.2em 0 0;
}

.page-news .teaser-text p {
  padding-bottom: 0.6rem;
}

.ui-widget-content,
.ui-widget-header {
  background: inherit;
}

.ui-tabs .ui-tabs-nav li.ui-state-active a,
.ui-tabs .ui-tabs-nav li.ui-state-hover a,
.ui-tabs .ui-tabs-nav li.ui-tabs-selected a {
  color: $wcm-white;
}

.ui-tabs .ui-tabs-panel {
    border-top: 1px solid #d8d2c9;
    padding: 1em 0;
}

.pic-with-caption {
  float: right;
  margin-left: 1em;
  width: 20%;
}

.pic-with-caption .caption {
}

.logo-position {
  @include clearfix();
  width: 100%;
  margin-bottom: 0.5em;
  display: none;
  @include breakpoint($lg){
   float: right;
   width: 40%;
   margin: 0 0 0.5em 0.5em;
   display: block;
  }
  @include breakpoint($md){
   float: right;
   width: 40%;
   margin: 0 0 0.5em 0.5em;
   display: block;
  }
}

tr.even, tr.odd,
.mobile-table tr.odd th, .mobile-table tr.odd,
.mobile-table tr.even th, .mobile-table tr.even  {
    background-color: inherit;
    border-bottom: 1px solid #D8D2C9;
}

.even td, .odd td,
th {
  padding: 0.5em 0.2em;
}

table.mobile-table {
  margin: 0;
}

.profile-list-image{
  width: 140px;
  margin: 0 auto;
  @include breakpoint($lg){
    margin: 0 auto .1em;
  }
  @include breakpoint($md){
    margin: 0 auto .2em;
  }
  img {
    width: 140px;
    height: 140px;
    margin-bottom: 0;
  }
}

.pane-profiles-panel-pane-2,
.pane-profiles-panel-pane-3, .pane-profiles-panel-pane-4,
.pane-profiles-panel-pane-5, .pane-profiles-panel-pane-6,
.pane-profiles-panel-pane-7, .pane-profiles-panel-pane-8,
.pane-profiles-panel-pane-9, .pane-profiles-panel-pane-10,
.pane-profiles-panel-pane-11{
  .views-row{
    @include breakpoint($lg){
      margin-right: 8px!important;
    }
    @include breakpoint($md){
      margin-right: 8px!important;
    }
      margin-right: 5%!important;
  }
}

.page-node-4 .views-row-odd,
.page-node-4 .views-row-even,
.page-node-5 .views-row-odd,
.page-node-5 .views-row-even,
.page-node-6 .views-row-odd,
.page-node-6 .views-row-even,
.page-node-7 .views-row-odd,
.page-node-7 .views-row-even  {
  @include clearfix();
  float: left;
  text-align: left;
  width: 45%!important;
  margin: 0 5% 1em 0;
  min-height: 17em;
  @include breakpoint($md){
    width: 28.33%!important;
    margin-right: 5%!important;
  }
  @include breakpoint($lg){
    width: 45%;
    margin-right: 5%;
  }
}

.page-about-us-our-faculty a > img,
.page-our-people-faculty a > img,
.page-our-people-crna-roster a > img {
  border-radius: 0;
}

.poster {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #e2e2d8;
}

.poster img {
  margin-bottom: 0.5em;
}

/*NEWS PAGE VIEW*/
img.panopoly-image-quarter {
  @include clearfix();
  @include breakpoint($xs){
    float: none !important;
    width: 100% !important;
    max-width: 100% !important;
    @include clearfix;
    }
  @include breakpoint($sm){
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important;
    }
  @include breakpoint($md){
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important;
    }
  @include breakpoint($lg){
    float: right !important;
    margin: 20px 0px 0px 10px;
    width: 30% !important;
    max-width: 30% !important;
    }
  }

.view-news{
  .news-story__type--1 {
    background: $wcm-bright-orange;
    padding: 0px 10px;
    float: right;
    @include breakpoint($xs){
      width: 100%;
    }
    @include breakpoint($sm){
      width: 30%;
      margin-right: 8px;
    }
    @include breakpoint($md){
      width: 30%;
      margin-right: 8px;      }
    @include breakpoint($lg){
      width: 30%;
      margin-right: 8px;      }
  }
  .news-story__type a {
    color: $wcm-white;
  }

  .news-story__type a:hover {
    text-decoration: none;
  }

  .teaser-title{
    @include breakpoint($xs){
      margin: 0px 0 0;
    }
    @include breakpoint($sm){
      margin: -20px 0 0;
    }
    @include breakpoint($md){
      margin: -20px 0 0;
    }
    @include breakpoint($lg){
      margin: -20px 0 0;
    }
  }

  .teaser-text{
    padding: 0px 0px 10px 0px;
  }

  .view-teaser{
    padding: 0px 0px 40px 0px;
  }

  .news-story__tags, .story-tags {
  margin: 20px 0 0;
  font-size: 12px;
  background: url(/sites/all/themes/wcm_cpo_subtheme/images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding: 0px 0px 0px 22px;
}
}

.news-story__tags, .story-tags {
  margin: 20px 0 0;
  font-size: 12px;
  background: url(/sites/all/themes/wcm_cpo_subtheme/images/tag.png) left center no-repeat;
  background-size: 15px 15px;
  padding: 10px 0px 0px 22px;
}

.social-share {
  margin: 15px 0px 10px 0px;
  padding: 0;
}


/*FAQ*/
.question:before{
  display: none;
}


/*SIDEBAR*/
.information-sidebar {
  font-size: 14px;
  text-align: center;
}

/* =News Post
----------------------------------------------------------*/

.news-featured-image .hero-image img {
  width: auto;
}


